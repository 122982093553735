.pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    block-size: var(--tube-height-sm);
    min-inline-size: var(--tube-height-sm);
    padding: var(--space-8);
    padding-block: 0.375rem;
    gap: 6px;
    border: 1px solid var(--ui-border);
    border-radius: var(--border-radius-sm);
    color: var(--pill-color, var(--display-onlight-secondary));
}

.content {
    white-space: nowrap;
}

.prefixIcon {
    inline-size: var(--icon-lg);
    block-size: var(--icon-lg);
    flex-shrink: 0;
    color: var(--icon-color);
}

.hovered {
    background-color: var(--ui-background);
}

.dueDateToday {
    --pill-color: var(--display-onlight-color-green);
    --icon-color: var(--display-onlight-color-green);
}

.dueDateTomorrow {
    --pill-color: var(--display-onlight-color-yellow);
    --icon-color: var(--display-onlight-color-yellow);
}

.dueDateNextWeek {
    --pill-color: var(--productui-meta-purple);
    --icon-color: var(--productui-meta-purple);
}

.dueDateThisWeekend {
    --pill-color: var(--productui-meta-blue);
    --icon-color: var(--productui-meta-blue);
}

.priorityP1 {
    --icon-color: var(--display-onlight-color-red);
}

.priorityP2 {
    --icon-color: var(--productui-meta-orange);
}

.priorityP3 {
    --icon-color: var(--display-onlight-color-blue);
}

.priorityP4 {
    --icon-color: var(--display-onlight-tertiary);
}

.labelCustom {
    --icon-color: var(--display-onlight-color-blue);
}

.overflow {
    padding: 6px;
}

.fillIcon {
    color: var(--display-onlight-tertiary);
}
