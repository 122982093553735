.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.items {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-16);
}

.item {
    --border-style: solid;

    inline-size: 241px;
    border-radius: var(--border-radius-lg);
    border: 1px var(--border-style) var(--box-outline-border);
    overflow: hidden;

    &.dashed {
        --border-style: dashed;

        .itemImage {
            border-bottom: none;
        }
    }
}

.itemImage {
    block-size: 108px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: var(--border-radius-full);
    }

    border-bottom: 1px var(--border-style) var(--box-outline-border);
}

.itemDescription {
    color: var(--display-onlight-secondary);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
