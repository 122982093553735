.product-ui-task-attribute-pill_pill__24EhT {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    block-size: var(--tube-height-sm);
    min-inline-size: var(--tube-height-sm);
    padding: var(--space-8);
    padding-block: 0.375rem;
    gap: 6px;
    border: 1px solid var(--ui-border);
    border-radius: var(--border-radius-sm);
    color: var(--pill-color, var(--display-onlight-secondary));
}

.product-ui-task-attribute-pill_content__6XBmf {
    white-space: nowrap;
}

.product-ui-task-attribute-pill_prefixIcon__r6BA_ {
    inline-size: var(--icon-lg);
    block-size: var(--icon-lg);
    flex-shrink: 0;
    color: var(--icon-color);
}

.product-ui-task-attribute-pill_hovered__xZEDZ {
    background-color: var(--ui-background);
}

.product-ui-task-attribute-pill_dueDateToday__dt1DN {
    --pill-color: var(--display-onlight-color-green);
    --icon-color: var(--display-onlight-color-green);
}

.product-ui-task-attribute-pill_dueDateTomorrow__rssI4 {
    --pill-color: var(--display-onlight-color-yellow);
    --icon-color: var(--display-onlight-color-yellow);
}

.product-ui-task-attribute-pill_dueDateNextWeek__caq0D {
    --pill-color: var(--productui-meta-purple);
    --icon-color: var(--productui-meta-purple);
}

.product-ui-task-attribute-pill_dueDateThisWeekend__GA6mr {
    --pill-color: var(--productui-meta-blue);
    --icon-color: var(--productui-meta-blue);
}

.product-ui-task-attribute-pill_priorityP1__LRvaZ {
    --icon-color: var(--display-onlight-color-red);
}

.product-ui-task-attribute-pill_priorityP2__0khKU {
    --icon-color: var(--productui-meta-orange);
}

.product-ui-task-attribute-pill_priorityP3__86cKr {
    --icon-color: var(--display-onlight-color-blue);
}

.product-ui-task-attribute-pill_priorityP4__d5SsL {
    --icon-color: var(--display-onlight-tertiary);
}

.product-ui-task-attribute-pill_labelCustom__1ak44 {
    --icon-color: var(--display-onlight-color-blue);
}

.product-ui-task-attribute-pill_overflow__dmXnG {
    padding: 6px;
}

.product-ui-task-attribute-pill_fillIcon__mD8UT {
    color: var(--display-onlight-tertiary);
}

.quick-add_quickAddContainer__vnmuO {
    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-xl);
}

.quick-add_details__98fXu {
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
    padding: var(--space-16);
    border-bottom: 1px solid var(--ui-divider);
}

.quick-add_inputFields__JcRkf {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.quick-add_taskContainer__9XC6W {
    display: flex;
    align-items: center;
    min-block-size: var(--tube-height-sm);
}

.quick-add_taskText__F7fDZ {
    display: inline-flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    color: var(--display-onlight-primary);
}

.quick-add_footer__ayIRU {
    padding: var(--space-16);
    padding-inline-start: var(--space-8);
}

.quick-add_attributesContainer__L8PaJ {
    position: relative;
    display: flex;
    gap: var(--space-8);
}

.quick-add_intentContainer__WRlkT {
    align-items: center;
    border-radius: var(--border-radius-sm);
}

.quick-add_placeholder__jluoW {
    color: var(--display-onlight-tertiary);
}

.quick-add_highlight__5I63X {
    display: inline-flex;
    padding-inline: var(--space-8);
    background-color: var(--productui-keyword-highlight-background);
}

.quick-add_textContainer__FCDf_ {
    position: relative;
}

.quick-add_footerContainer__Rty68 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quick-add_footerSpacer__G87RQ {
    flex: 1 1;
}

.quick-add_descriptionText__dmkOc {
    color: var(--display-onlight-primary);
}

.quick-add_descriptionText__dmkOc.quick-add_placeholder__jluoW {
        color: var(--display-onlight-tertiary);
    }

.product-ui-template-gallery_header__dtkyh {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-ui-template-gallery_sections__snavX {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.product-ui-template-gallery_items__LO_78 {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-16);
}

.product-ui-template-gallery_item__1WZyN {
    --border-style: solid;

    inline-size: 241px;
    border-radius: var(--border-radius-lg);
    border: 1px var(--border-style) var(--box-outline-border);
    overflow: hidden;
}

.product-ui-template-gallery_item__1WZyN.product-ui-template-gallery_dashed__yrCWX {
        --border-style: dashed;
    }

.product-ui-template-gallery_item__1WZyN.product-ui-template-gallery_dashed__yrCWX .product-ui-template-gallery_itemImage__QXV14 {
            border-bottom: none;
        }

.product-ui-template-gallery_itemImage__QXV14 {
    block-size: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-ui-template-gallery_itemImage__QXV14 img {
        border-radius: var(--border-radius-full);
    }

.product-ui-template-gallery_itemImage__QXV14 {

    border-bottom: 1px var(--border-style) var(--box-outline-border);
}

.product-ui-template-gallery_itemDescription__oqkpd {
    color: var(--display-onlight-secondary);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

