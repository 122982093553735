.quickAddContainer {
    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-xl);
}

.details {
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
    padding: var(--space-16);
    border-bottom: 1px solid var(--ui-divider);
}

.inputFields {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.taskContainer {
    display: flex;
    align-items: center;
    min-block-size: var(--tube-height-sm);
}

.taskText {
    display: inline-flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    color: var(--display-onlight-primary);
}

.footer {
    padding: var(--space-16);
    padding-inline-start: var(--space-8);
}

.attributesContainer {
    position: relative;
    display: flex;
    gap: var(--space-8);
}

.intentContainer {
    align-items: center;
    border-radius: var(--border-radius-sm);
}

.placeholder {
    color: var(--display-onlight-tertiary);
}

.highlight {
    display: inline-flex;
    padding-inline: var(--space-8);
    background-color: var(--productui-keyword-highlight-background);
}

.textContainer {
    position: relative;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerSpacer {
    flex: 1;
}

.descriptionText {
    color: var(--display-onlight-primary);

    &.placeholder {
        color: var(--display-onlight-tertiary);
    }
}
